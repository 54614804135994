import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { xcss, Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { FILTER_PREFERENCE } from '@atlassian/jira-business-preferences/src/constants.tsx';
import { useViewPreference } from '@atlassian/jira-business-preferences/src/controllers/view-preferences-context/index.tsx';
import { ThemedButton } from '@atlassian/jira-business-theme-components/src/ui/themed-button/ThemedButton.tsx';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { srcLight as noResultsIllustration } from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/empty/components/search-no-results';
import { useIntl } from '@atlassian/jira-intl';
import { useRouterActions } from '@atlassian/react-resource-router';
import { SIMPLE_SEARCH_PARAM } from '../../constants';
import messages from './messages';

type Props = {
	reason?: 'INVALID_JQL' | 'NO_RESULTS';
};

export const ThemedFilterEmptyState = ({ reason }: Props) => {
	// @ts-expect-error - TS2339 - Property 'updateQueryParam' does not exist on type 'BoundActions<EntireRouterState, PublicRouterActions>'.
	const { updateQueryParam } = useRouterActions();
	const [, setFilters] = useViewPreference(FILTER_PREFERENCE);
	const { formatMessage } = useIntl();

	const handleOnClear = useCallback(() => {
		// persist that the user has cleared filters in view preferences
		// but do not push the change to the URL as we're already pushing a history state
		// to clear both filters and simple search below
		setFilters(undefined, 'none');
		updateQueryParam(
			{ filter: undefined, quickFilters: undefined, [SIMPLE_SEARCH_PARAM]: undefined },
			'push',
		);
	}, [updateQueryParam, setFilters]);

	const message =
		reason === 'INVALID_JQL'
			? formatMessage(messages.invalidStateHeading)
			: formatMessage(messages.emptyStateHeading);

	return (
		<Box xcss={wrapperStyles}>
			<Illustration src={noResultsIllustration} alt="" />
			<HeadingWrapper>{message}</HeadingWrapper>
			<ThemedButton appearance="primary" onClick={handleOnClear}>
				{formatMessage(messages.clearFiltersButton)}
			</ThemedButton>
		</Box>
	);
};

const wrapperStyles = xcss({
	textAlign: 'center',
	margin: 'auto',
	width: '450px',
	padding: 'space.300',
	borderRadius: 'border.radius.200',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	background: Tokens.ELEVATION_SURFACE_CONTRAST_BOLDER,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeadingWrapper = styled.h3({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: Tokens.COLOR_TEXT,
	marginBottom: token('space.300', '24px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Illustration = styled.img({
	width: '208px',
	height: '190px',
});
