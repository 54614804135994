import { useCallback } from 'react';
import { SELECTED_ISSUE_PARAM } from '@atlassian/jira-business-constants';
import { isModifiedEvent } from '@atlassian/jira-business-modified-click-event';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useQueryParam } from '@atlassian/react-resource-router';
import messages from './messages';

export const useShowCreateSuccessFlag = () => {
	const { formatMessage } = useIntl();
	const { showFlag, dismissFlag } = useFlagsService();
	const [, setSelectedIssueKey] = useQueryParam(SELECTED_ISSUE_PARAM);

	return useCallback(
		(issueKey: string) => {
			const flagId = `jwm-create-success-flag-${issueKey}`;
			showFlag({
				id: flagId,
				isAutoDismiss: true,
				type: 'success',
				title: formatMessage(messages.createSuccessFlagTitle, {
					issueKey,
				}),
				actions: [
					{
						content: messages.viewIssueLinkText,
						href: `/browse/${issueKey}`,
						onClick: (event) => {
							if (event && !isModifiedEvent(event)) {
								setSelectedIssueKey(issueKey);
								event.preventDefault();
								event.stopPropagation();
							}
							dismissFlag(flagId);
						},
					},
				],
			});
		},
		[dismissFlag, formatMessage, setSelectedIssueKey, showFlag],
	);
};
