import React, { forwardRef, useMemo } from 'react';
import { styled } from '@compiled/react';
import Button, { type Appearance } from '@atlaskit/button';
import FilterIcon from '@atlaskit/icon/glyph/filter';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import type { TriggerProps } from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';
import { ThemedButton } from '@atlassian/jira-business-theme-components/src/ui/themed-button/ThemedButton.tsx';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import messages from './messages';

const FILTER_ICON = <FilterIcon label="" size="small" />;

type Props = Omit<TriggerProps, 'ref'> & {
	isSelected: boolean;
	hasFiltersApplied: boolean;
	count: number;
	onClick: () => void;
	withThemedButton?: boolean;
	isDisabled?: boolean;
	appearance?: Appearance;
};

export const Trigger = forwardRef<HTMLDivElement, Props>(
	(
		{
			onClick,
			isSelected,
			count,
			hasFiltersApplied,
			withThemedButton = true,
			isDisabled,
			appearance = 'subtle',
			...otherProps
		},
		ref,
	) => {
		const { formatMessage } = useIntl();

		const filterMessageOld = useMemo(() => {
			if (count > 0) {
				return formatMessage(messages.filtersCount, { filters: count });
			}
			if (hasFiltersApplied) {
				return formatMessage(messages.buttonWithFilters);
			}
			return formatMessage(messages.filterButton);
		}, [count, formatMessage, hasFiltersApplied]);

		const filterMessage = useMemo(() => {
			if (hasFiltersApplied && count === 0) {
				return formatMessage(messages.buttonWithFilters);
			}

			return formatMessage(messages.filterButton);
		}, [count, formatMessage, hasFiltersApplied]);

		const TriggerButton = withThemedButton ? ThemedButton : Button;

		return isVisualRefreshEnabled() ? (
			<TriggerButton
				{...otherProps}
				aria-label={formatMessage(messages.filtersCount, { filters: count })}
				ref={ref}
				isDisabled={isDisabled}
				isSelected={isSelected || count > 0}
				onClick={onClick}
				testId="business-filters.ui.filters.trigger.button-wrapper"
				iconAfter={count === 0 ? <ChevronDownIcon label="" color="currentColor" /> : undefined}
			>
				<ButtonContents>
					{filterMessage}
					{count > 0 && <BadgeContents>{count}</BadgeContents>}
				</ButtonContents>
			</TriggerButton>
		) : (
			<TriggerButton
				{...otherProps}
				ref={ref}
				testId="business-filters.ui.filters.trigger.button-wrapper"
				iconBefore={FILTER_ICON}
				isSelected={isSelected}
				onClick={onClick}
				appearance={fg('jira-calendar-business-theme') ? appearance : 'subtle'}
				isDisabled={isDisabled}
			>
				<ButtonContents>{filterMessageOld}</ButtonContents>
			</TriggerButton>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonContents = styled.div({
	display: 'flex',
	flexDirection: 'row',
	gap: token('space.075'),
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const BadgeContents = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values
	backgroundColor: Tokens.COLOR_BACKGROUND_NEUTRAL,
	padding: '0 0.25rem',
	height: '1rem',
	minWidth: '1rem',
	borderRadius: token('border.radius.050'),
	font: token('font.body'),
});
