export const JWM_UPDATE_ISSUE_SUBSCRIPTION = `
  subscription JwmIssueUpdatedSubscription(
    $cloudId: ID!
    $projectId: String!
  ) {
    jira {
      onIssueUpdatedByProject(cloudId: $cloudId, projectId: $projectId) @optIn(to: ["JiraIssueSubscriptions"]) {
        issueId
      }
    }
  }
`;
