import mergeWith from 'lodash/mergeWith';
import trim from 'lodash/trim';
import { subDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import {
	type Operator,
	type Operand,
	JastBuilder,
	AbstractJastVisitor,
	COMPOUND_OPERATOR_AND,
	type CompoundClause,
	type TerminalClause,
	OPERAND_TYPE_LIST,
	OPERAND_TYPE_VALUE,
	OPERAND_TYPE_KEYWORD,
	OPERATOR_GT_EQUALS,
	OPERATOR_LT,
	OPERATOR_EQUALS,
	OPERATOR_IN,
} from '@atlaskit/jql-ast';
import type { BusinessIssueField } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	CATEGORY_TYPE,
	COMPONENTS_TYPE,
	LABELS_TYPE,
	PRIORITY_TYPE,
} from '@atlassian/jira-platform-field-config';
import {
	type DateValue,
	type Field,
	type FieldConfig,
	type Filters as FilterType,
	isDateField,
	type SelectOption,
	type Value,
} from '../../common/types';
import { EMPTY_FILTER_VALUE } from '../../constants';
import type { Resolver, Resolvers } from '../types';

type GetShouldConvertCustomField = (fieldName: string) => Boolean;

const START_DATE_JQL = 'Start date[Date]';
const EMPTY = 'EMPTY';

const cleanString = (value: string) => value.trim();

// some filters should display options retrieved from the URL parameters even when they are not returned amongst the useFieldConfig field values
const shouldIncludeUnknownOption = (filterField?: BusinessIssueField): boolean => {
	const allowedFieldTypes = new Set<string>([
		COMPONENTS_TYPE,
		LABELS_TYPE,
		PRIORITY_TYPE,
		CATEGORY_TYPE,
	]);
	return allowedFieldTypes.has(filterField?.type ?? '');
};

const getFieldIdFromJql = (
	rawFieldName: string,
	getShouldConvertCustomField?: GetShouldConvertCustomField,
) => {
	const fieldName = trim(rawFieldName, '"');

	const shouldConvertCustomField = getShouldConvertCustomField
		? getShouldConvertCustomField(fieldName)
		: true;

	// `cf[id]` -> `customfield_${id}`.
	if (fieldName.indexOf('cf[') === 0 && shouldConvertCustomField) {
		const extraction = new RegExp(/cf\[(.+)\]/).exec(fieldName);

		if (!extraction) {
			return fieldName;
		}

		const fieldId = extraction[1];
		return `customfield_${fieldId}`;
	}

	const startDateJql = START_DATE_JQL;

	// update this if we add a start date or due date filter
	if (fieldName === startDateJql || fieldName === 'due') {
		return 'dateRange';
	}

	return fieldName;
};

const transformIntoOptions = async (
	list: string[],
	field: Field,
	resolver: Resolver,
	filterField?: BusinessIssueField,
): Promise<SelectOption[]> => {
	if (resolver && typeof resolver === 'function') {
		const ids = list.map((value) =>
			fg('wanjel-fix-filters-including-whitespace') ? value : cleanString(value),
		);

		return resolver(ids);
	}

	const allowUnknownOption = shouldIncludeUnknownOption(filterField);

	return list
		.map((value) => {
			const cleanValue = fg('wanjel-fix-filters-including-whitespace') ? value : cleanString(value);

			if (field && !isDateField(field) && Array.isArray(field.options)) {
				const optionInField = field.options.find((option) => {
					if ((option == null || option.value == null) && !option.isEmptyOption) {
						return null;
					}
					if (option.isEmptyOption) {
						return value === EMPTY;
					}

					return option.value === cleanValue;
				});

				if (!optionInField && allowUnknownOption) {
					return {
						value: cleanValue,
						label: cleanValue,
					};
				}

				return optionInField;
			}

			return undefined;
		})
		.filter((item): item is SelectOption => item != null && 'value' in item);
};

export class FilterParsingVisitor extends AbstractJastVisitor<Promise<FilterType>> {
	fieldConfig: FieldConfig;

	filterFields: BusinessIssueField[];

	resolvers: Resolvers;

	constructor(fieldConfig: FieldConfig, filterFields: BusinessIssueField[], resolvers: Resolvers) {
		super();

		this.fieldConfig = fieldConfig;
		this.filterFields = filterFields;
		this.resolvers = resolvers;
	}

	getOption = (fieldName: string, items: string[]) =>
		transformIntoOptions(
			items,
			this.fieldConfig[fieldName],
			this.resolvers[fieldName],
			this.filterFields.find((field) => field.id === fieldName),
		);

	visitNotClause(): Promise<FilterType> {
		throw new Error('JQL contains invalid clause: NOT');
	}

	visitCompoundClause = async (compoundClause: CompoundClause): Promise<FilterType> => {
		if (fg('wanjel-fix-deserialise-filters-sentry-errors')) {
			const clauseMap: FilterType = {};
			const operator = compoundClause.operator.value;

			if (operator === COMPOUND_OPERATOR_AND) {
				const clausePromises = await Promise.all(
					compoundClause.clauses.map((clause) => clause.accept(this)),
				);
				return clausePromises.reduce(
					(result, clause) => this.aggregateResultSync(clause, result),
					clauseMap,
				);
			}

			throw new Error(`JQL contains invalid operator between clauses: ${operator}`);
		}
		const clauseMap: Promise<FilterType> = Promise.resolve({});
		const operator = compoundClause.operator.value;

		if (operator === COMPOUND_OPERATOR_AND) {
			return compoundClause.clauses.reduce(
				(result, clause) => this.aggregateResult(clause.accept(this), result),
				clauseMap,
			);
		}

		throw new Error(`JQL contains invalid operator between clauses: ${operator}`);
	};

	parseDateOperand = (operator: Operator, operand: Operand): DateValue => {
		if (operand?.operandType === OPERAND_TYPE_VALUE) {
			let date = formatInTimeZone(new Date(operand.value), 'UTC', 'yyyy-MM-dd');
			if (operator.value === OPERATOR_LT) {
				date = formatInTimeZone(subDays(new Date(operand.value), 1), 'UTC', 'yyyy-MM-dd');
			}
			const dateType = operator.value === OPERATOR_LT ? 'to' : 'from';
			return { value: { [dateType]: date } };
		}
		throw new Error(`JQL cannot parse date operand type: ${operand.operandType}`);
	};

	parseSelectOperand = async (fieldName: string, operand: Operand): Promise<SelectOption[]> => {
		if (operand?.operandType === OPERAND_TYPE_LIST) {
			const optionPromises = operand.values.flatMap((value) =>
				this.parseSelectOperand(fieldName, value),
			);

			return (await Promise.all(optionPromises)).flatMap((option) => option);
		}
		if (operand?.operandType === OPERAND_TYPE_VALUE) {
			return this.getOption(fieldName, [operand.value]);
		}
		if (operand?.operandType === OPERAND_TYPE_KEYWORD) {
			return [
				{
					isEmptyOption: true,
					value: EMPTY_FILTER_VALUE,
					label: '',
				},
			];
		}
		throw new Error(`JQL cannot parse select operand type: ${operand.operandType}`);
	};

	visitTerminalClause = async (terminalClause: TerminalClause): Promise<FilterType> => {
		const { field, operand, operator } = terminalClause;
		if (!operand || !operator) {
			return Promise.resolve({});
		}
		const getShouldConvertCustomField: GetShouldConvertCustomField = (fieldName: string) =>
			// should convert cf[] -> customfield_ if it's not a field from version 2
			!(this.fieldConfig[fieldName] && this.fieldConfig[fieldName].fieldConfigVersion === 2);
		const fieldName = getFieldIdFromJql(field.value, getShouldConvertCustomField);
		if (!Object.keys(this.fieldConfig).includes(fieldName)) {
			throw new Error(`JQL contains unsupported fields: ${fieldName}`);
		}
		let value: Value = [];
		if (operator.value === OPERATOR_GT_EQUALS || operator.value === OPERATOR_LT) {
			value = this.parseDateOperand(operator, operand);
		} else if (operator.value === OPERATOR_EQUALS || operator.value === OPERATOR_IN) {
			value = await this.parseSelectOperand(fieldName, operand);
		} else {
			throw new Error(`JQL contains unsupported operator in terminal clause: ${operator.value}`);
		}
		return {
			[fieldName]: value,
		};
	};

	protected defaultResult(): Promise<FilterType> {
		return Promise.resolve({});
	}

	/**
	 * @param aggregate new ClauseMap to add into nextResult
	 * @param nextResult ClauseMap with previous results
	 * @returns ClauseMap with the merged results adding the aggregate at the end
	 */
	protected async aggregateResult(
		aggregate: Promise<FilterType>,
		nextResult: Promise<FilterType>,
	): Promise<FilterType> {
		// First parameter is the object where we want to add the values and the second the new values to merge into the first object.
		return mergeWith(await nextResult, await aggregate, (destValue, srcValue) => {
			if (Array.isArray(srcValue)) {
				return srcValue.concat(destValue ?? []);
			}
			return undefined;
		});
	}

	aggregateResultSync(aggregate: FilterType, nextResult: FilterType): FilterType {
		return mergeWith(nextResult, aggregate, (destValue, srcValue) => {
			if (Array.isArray(srcValue)) {
				return srcValue.concat(destValue ?? []);
			}
			return undefined;
		});
	}
}

export type JQLDeserialiser = (
	jql: string,
	fieldConfig: FieldConfig,
	filterFields: BusinessIssueField[],
	resolvers: Resolvers,
) => Promise<FilterType>;

export const deserialiseJql: JQLDeserialiser = async (
	jql,
	fieldConfig,
	filterFields,
	resolvers,
) => {
	// Create the syntax tree for the filters JQL
	const builder = new JastBuilder();
	const jast = builder.build(jql);

	if (jast.query?.orderBy != null) {
		throw new Error('JQL contains unsupported ORDER BY');
	}

	// Parse the JQL AST into a FilterType object
	const parser = new FilterParsingVisitor(fieldConfig, filterFields, resolvers);
	const parsedJql = await jast.query?.accept(parser);
	return parsedJql ?? {};
};
