export const JWM_CREATE_ISSUE_SUBSCRIPTION = `
  subscription JwmIssueCreatedSubscription(
    $cloudId: ID!
    $projectId: String!
  ) {
    jira {
      onIssueCreatedByProject(cloudId: $cloudId, projectId: $projectId) @optIn(to: ["JiraIssueSubscriptions"]) {
        issueId
      }
    }
  }
`;
