import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { INLINE_CREATE_PARAM } from '@atlassian/jira-business-constants';
import { ThemedButton } from '@atlassian/jira-business-theme-components/src/ui/themed-button/ThemedButton.tsx';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useQueryParam } from '@atlassian/react-resource-router';
import { Illustration } from './illustration';
import messages from './messages';

export const EmptyBoardComponent = () => {
	const { formatMessage } = useIntl();
	const emptyBoardTitle = formatMessage(messages.emptyBoardTitle);
	const emptyBoardDescription = formatMessage(messages.emptyBoardDescription);
	const buttonTitle = formatMessage(messages.emptyBoardCreateItem);
	const [, setInlineCreateParam] = useQueryParam(INLINE_CREATE_PARAM);

	const handleItemCreateClick = useCallback(
		(_e: React.MouseEvent<HTMLElement>) => {
			setInlineCreateParam('true');
		},
		[setInlineCreateParam],
	);

	return (
		<MessageContainer>
			<EmptyBoardImageContainer>
				<Illustration />
			</EmptyBoardImageContainer>
			<Box xcss={emptyBoardTextContainerStyles}>
				<Header>{emptyBoardTitle}</Header>
				<p>{emptyBoardDescription}</p>
			</Box>
			<ThemedButton
				appearance="primary"
				testId="work-management-board.ui.board.empty-state.today"
				onClick={handleItemCreateClick}
			>
				{buttonTitle}
			</ThemedButton>
		</MessageContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MessageContainer = styled.div({
	maxWidth: '400px',
	boxSizing: 'border-box',
	textAlign: 'center',
	padding: token('space.300', '24px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	background: Tokens.ELEVATION_SURFACE_CONTRAST_BOLDER,
	borderRadius: token('border.radius.200', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyBoardImageContainer = styled.div({
	flex: '1 0 auto',
});

const emptyBoardTextContainerStyles = xcss({
	marginBottom: 'space.300',
	marginTop: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Header = styled.h2({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: Tokens.COLOR_TEXT,
});
