import type { AvatarProps } from '@atlaskit/avatar-group';
import { createSessionExpirableStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/session-storage/index.tsx';
import type { User } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';

export const mapUsersToAvatars = (users: User[]): AvatarProps[] =>
	users.map((user) => ({
		key: user.accountId,
		name: user.displayName,
		src: user.avatarUrls?.['32x32'],
	}));

export const inviteUsersSessionStorage = createSessionExpirableStorageProvider(
	'invite-users-on-project-create',
);
